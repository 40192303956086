import { lazy } from 'react';
import { createBrowserRouter, NavLink, RouterProvider } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import PageLayout from 'pages/PageLayout';
import ProtectedRoute from 'components/ProtectedRoute';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';

const Error404 = lazy(() => import('components/Error404'));
const CustomerList = lazy(() => import('pages/Customers/CustomerList'));
const Customer = lazy(() => import('pages/Customers/Customer'));
const VehicleList = lazy(() => import('pages/Vehicles/VehicleList'));
const Vehicle = lazy(() => import('pages/Vehicles/Vehicle'));
const DriverList = lazy(() => import('pages/Drivers/DriverList'));
const Driver = lazy(() => import('pages/Drivers/Driver'));
const TransporterList = lazy(() => import('pages/Transporters/TransporterList'));
const Transporter = lazy(() => import('pages/Transporters/Transporter'));
const TaxRateList = lazy(() => import('pages/TaxRates/TaxRateList'));
const TaxRate = lazy(() => import('pages/TaxRates/TaxRate'));
const Seller = lazy(() => import('pages/Sellers/Seller'));
const SellerList = lazy(() => import('pages/Sellers/SellerList'));
const Line = lazy(() => import('pages/Lines/Line'));
const LineList = lazy(() => import('pages/Lines/LineList'));
const Route = lazy(() => import('pages/Routes/Route'));
const RouteList = lazy(() => import('pages/Routes/RouteList'));
const Accessory = lazy(() => import('pages/Accessories/Accessory'));
const AccessoryList = lazy(() => import('pages/Accessories/AccessoryList'));
const VehicleStandard = lazy(() => import('pages/VehicleStandards/VehicleStandard'));
const VehicleStandardList = lazy(() => import('pages/VehicleStandards/VehicleStandardList'));
const Ranges = lazy(() => import('pages/Ranges/index'));

const EventualContract = lazy(() => import('pages/EventualContracts/EventualContract'));
const EventualContractsList = lazy(() => import('pages/EventualContracts/EventualContractsList'));


const Budget = lazy(() => import('pages/Budgets/Budget'));
const BudgetList = lazy(() => import('pages/Budgets/BudgetList'));
const CteosList = lazy(() => import('pages/Ct-eos/Ct-eosList'));
const CteosEmission = lazy(() => import('pages/Ct-eos/Ct-eosEmission'));
const Dashboard = lazy(() => import('pages/Dashboard'));
//const CompanyList = lazy(() => import('pages/Companies/CompanyList'));
const Company = lazy(() => import('pages/Companies/Company'));
//const EstablishmentList = lazy(() => import('pages/Establishments/EstablishmentList'));
const Establishment = lazy(() => import('pages/Establishments/Establishment'));
const OperationsList = lazy(() => import('pages/Operations/OperationList'));
const Operation = lazy(() => import('pages/Operations/Operation'));
const Moviments = lazy(() => import('pages/Moviments'));
const Installments = lazy(() => import('pages/installments'));
const Documents = lazy(() => import('pages/Documents'));
const Task = lazy(() => import('pages/Tasks/Task'));
const TaskList = lazy(() => import('pages/Tasks/TaskList'));
const UserProfiles = lazy(() => import('pages/UserProfiles/ProfileList'));
const UserProfile = lazy(() => import('pages/UserProfiles/UserProfile'));
const UserList = lazy(() => import('pages/Users/UserList'));
const UserPage = lazy(() => import('pages/Users/CreateUser'));
const Support = lazy(() => import('pages/Support'));
const Help = lazy(() => import('pages/Help'));

function CrumbLink({ to, children, ...props }){
  return(
    <Link {...props} component={NavLink} to={to}>{children}</Link>
  )
};

export const router = (t) => createBrowserRouter([
  {
    path: '/login',
    element: <Login/>
  },
  {
    path: '/trocar_senha',
    element: <ResetPassword/>
  },
  {
    path: '/',
    element: <ProtectedRoute/>,
    children: [
      {
        element: <PageLayout/>,
        children: [
          {
            index: true,
            element: <Dashboard/>,
            handle: {
              title: t('Dashboard')
            }
          },
          {
            path: '/clientes',
            handle: {
              crumb: <CrumbLink to='/clientes'>{t('Clientes')}</CrumbLink>,
              permission: 'customers'
            },
            children: [
              {
                index: true,
                element: <CustomerList/>,
                handle: {
                  title: t('Clientes')
                },
              },
              {
                path: '/clientes/novo',
                element: <Customer/>,
                handle: {
                  title: t('Cadastrar Cliente'),
                  crumb: <CrumbLink>{t('Cadastrar Cliente')}</CrumbLink>,
                }
              },
              {
                path: '/clientes/:guid',
                element: <Customer/>,
                handle: {
                  title: t('Editar Cliente'),
                  crumb: <CrumbLink>{t('Editar Cliente')}</CrumbLink>,
                }
              }
            ]
          },
          {
            path: '/transportadores',
            handle: {
              crumb: <CrumbLink to='/transportadores'>{t('Transportadores')}</CrumbLink>,
              permission: 'transporters'
            },
            children: [
              {
                index: true,
                element: <TransporterList/>,
                handle: {
                  title: t('Transportadores')
                },
              },
              {
                path: '/transportadores/novo',
                element: <Transporter/>,
                handle: {
                  title: t('Cadastrar Transportador'),
                  crumb: <CrumbLink>{t('Cadastrar Transportador')}</CrumbLink>,
                }
              },
              {
                path: '/transportadores/:guid',
                element: <Transporter/>,
                handle: {
                  title: t('Editar Transportador'),
                  crumb: <CrumbLink>{t('Editar Transportador')}</CrumbLink>,
                }
              }
            ]
          },
          {
            path: '/aliquotas',
            handle: {
              crumb: <CrumbLink to='/aliquotas'>{t('Alíquotas')}</CrumbLink>,
              permission: 'taxrates'
            },
            children: [
              {
                index: true,
                element: <TaxRateList/>,
                handle: {
                  title: t('Alíquotas')
                },
              },
              {
                path: '/aliquotas/novo',
                element: <TaxRate/>,
                handle: {
                  title: t('Cadastrar Alíquota'),
                  crumb: <CrumbLink>{t('Cadastrar Alíquota')}</CrumbLink>,
                }
              },
              {
                path: '/aliquotas/:guid',
                element: <TaxRate/>,
                handle: {
                  title: t('Editar Alíquota'),
                  crumb: <CrumbLink>{t('Editar Alíquota')}</CrumbLink>,
                }
              }
            ]
          },
          {
            path: '/linhas',
            handle: {
              crumb: <CrumbLink to='/linhas'>{t('Linhas')}</CrumbLink>,
              permission: 'lines'
            },
            children: [
              {
                index: true,
                element: <LineList/>,
                handle: {
                  title: t('Linhas')
                },
              },
              {
                path: '/linhas/novo',
                element: <Line/>,
                handle: {
                  title: t('Cadastrar Linha'),
                  crumb: <CrumbLink>{t('Cadastrar Linha')}</CrumbLink>,
                }
              },
              {
                path: '/linhas/:guid',
                element: <Line/>,
                handle: {
                  title: t('Editar Linha'),
                  crumb: <CrumbLink>{t('Editar Linha')}</CrumbLink>,
                }
              }
            ]
          },
          {
            path: '/veiculos',
            handle: {
              crumb: <CrumbLink to='/veiculos'>{t('Veículos')}</CrumbLink>,
              permission: 'vehicles'
            },
            children: [
              {
                index: true,
                element: <VehicleList/>,
                handle: {
                  title: t('Veículos')
                },
              },
              {
                path: '/veiculos/novo',
                element: <Vehicle/>,
                handle: {
                  title: t('Cadastrar Veículo'),
                  crumb: <CrumbLink>{t('Cadastrar Veículo')}</CrumbLink>
                }
              },
              {
                path: '/veiculos/:guid',
                element: <Vehicle/>,
                handle: {
                  title: t('Editar Veículo'),
                  crumb: <CrumbLink>{t('Editar Veículo')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/motoristas',
            handle: {
              crumb: <CrumbLink to='/motoristas'>{t('Motoristas')}</CrumbLink>,
              permission: 'drivers'
            },
            children: [
              {
                index: true,
                element: <DriverList/>,
                handle: {
                  title: t('Motoristas')
                }
              },
              {
                path: '/motoristas/novo',
                element: <Driver/>,
                handle: {
                  title: t('Cadastrar Motorista'),
                  crumb: <CrumbLink>{t('Cadastrar Motorista')}</CrumbLink>
                }
              },
              {
                path: '/motoristas/:guid',
                element: <Driver/>,
                handle: {
                  title: t('Editar Motorista'),
                  crumb: <CrumbLink>{t('Editar Motorista')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/vendedores',
            handle: {
              crumb: <CrumbLink to='/vendedores'>{t('Vendedores')}</CrumbLink>,
              permission: 'sellers'
            },
            children: [
              {
                index: true,
                element: <SellerList/>,
                handle: {
                  title: t('Vendedores')
                }
              },
              {
                path: '/vendedores/novo',
                element: <Seller/>,
                handle: {
                  title: t('Cadastrar Vendedor'),
                  crumb: <CrumbLink>{t('Cadastrar Vendedor')}</CrumbLink>
                }
              },
              {
                path: '/vendedores/:guid',
                element: <Seller/>,
                handle: {
                  title: t('Editar Vendedor'),
                  crumb: <CrumbLink>{t('Editar Vendedor')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/padroes_veiculo',
            handle: {
              crumb: <CrumbLink to='/padroes_veiculo'>{t('Padrões Veículo')}</CrumbLink>,
              permission: 'padraoVeic'
            },
            children: [
              {
                index: true,
                element: <VehicleStandardList/>,
                handle: {
                  title: t('Padrões Veículo')
                }
              },
              {
                path: '/padroes_veiculo/novo',
                element: <VehicleStandard/>,
                handle: {
                  title: t('Cadastrar Padrão Veículo'),
                  crumb: <CrumbLink>{t('Padrão Veículo')}</CrumbLink>
                }
              },
              {
                path: '/padroes_veiculo/:guid',
                element: <VehicleStandard/>,
                handle: {
                  title: t('Editar Padrão Veículo'),
                  crumb: <CrumbLink>{t('Editar Padrão Veículo')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/faixas',
            element: <Ranges/>,
            handle: {
              title: 'faixas',
              crumb: <CrumbLink to='/faixas'>{t('Faixas')}</CrumbLink>,
              permission: 'ranges'
            }
          },
          {
            path: '/contrato_eventual',
            handle: {
              crumb: <CrumbLink to='/contrato_eventual'>{t('Contrato Eventual')}</CrumbLink>,
              permission: 'occasionalContract'
            },
            children: [
              {
                index: true,
                element: <EventualContractsList/>,
                handle: {
                  title: t('Contrato eventual')
                }
              },
              {
                path: '/contrato_eventual/novo',
                element: <EventualContract/>,
                handle: {
                  title: t('Cadastrar Contrato eventual'),
                  crumb: <CrumbLink>{t('Contrato Eventual')}</CrumbLink>
                }
              },
              {
                path: '/contrato_eventual/:guid',
                element: <VehicleStandard/>,
                handle: {
                  title: t('Editar Contrato Eventual'),
                  crumb: <CrumbLink>{t('Editar Contrato Eventual')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/acessorios_extras',
            handle: {
              crumb: <CrumbLink to='/acessorios_extras'>{t('Acessórios e Extras')}</CrumbLink>,
              permission: 'accessVehic'
            },
            children: [
              {
                index: true,
                element: <AccessoryList/>,
                handle: {
                  title: t('Acessórios e Extras')
                }
              },
              {
                path: '/acessorios_extras/novo',
                element: <Accessory/>,
                handle: {
                  title: t('Cadastrar Acessório ou Extra'),
                  crumb: <CrumbLink>{t('Cadastrar Acessório / Extra')}</CrumbLink>
                }
              },
              {
                path: '/acessorios_extras/:guid',
                element: <Accessory/>,
                handle: {
                  title: t('Editar Acessório / Extra'),
                  crumb: <CrumbLink>{t('Editar Acessório / Extra')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/rotas',
            handle: {
              crumb: <CrumbLink to='/rotas'>{t('Rotas')}</CrumbLink>,
              permission: 'routes'
            },
            children: [
              {
                index: true,
                element: <RouteList/>,
                handle: {
                  title: t('Rotas')
                }
              },
              {
                path: '/rotas/novo',
                element: <Route/>,
                handle: {
                  title: t('Cadastrar Rota'),
                  crumb: <CrumbLink>{t('Cadastrar Rota')}</CrumbLink>
                }
              },
              {
                path: '/rotas/:guid',
                element: <Route/>,
                handle: {
                  title: t('Editar Rota'),
                  crumb: <CrumbLink>{t('Editar Rota')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/cotacoes',
            handle: {
              crumb: <CrumbLink to='/cotacoes'>{t('Cotações')}</CrumbLink>,
              permission: 'budgets'
            },
            children: [
              {
                index: true,
                element: <BudgetList/>,
                handle: {
                  title: t('Cotações')
                },
              },
              {
                path: '/cotacoes/novo',
                element: <Budget/>,
                handle: {
                  title: t('Nova Cotação'),
                  crumb: <CrumbLink>{t('Nova Cotação')}</CrumbLink>
                }
              },
              {
                path: '/cotacoes/:guid',
                element: <Budget/>,
                handle: {
                  title: t('Editar Cotação'),
                  crumb: <CrumbLink>{t('Editar Cotação')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/cte-os',
            handle: {
              crumb: <CrumbLink to='/cte-os'>{t('Lançamentos de CT-e OS')}</CrumbLink>,
              permission: 'cte-os'
            },
            children: [
              {
                index: true,
                element: <CteosList/>,
                handle: {
                  title: t('Lançamentos de CT-e OS')
                },
              },
              {
                path: '/cte-os/novo',
                element: <CteosEmission/>,
                handle: {
                  title: t('Lançar CT-e OS'),
                  crumb: <CrumbLink>{t('Lançar CT-e OS')}</CrumbLink>
                }
              },
              {
                path: '/cte-os/:guid',
                element: <CteosEmission/>,
                handle: {
                  title: t('Editar CT-e OS'),
                  crumb: <CrumbLink>{t('Editar CT-e OS')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/movimentos',
            element: <Moviments/>,
            handle: {
              title: t('Movimentos mensais'),
              crumb: <CrumbLink>{t('Movimentos mensais')}</CrumbLink>,
              permission: 'moviments'
            }
          },
          {
            path: '/vencimentos',
            element: <Installments/>,
            handle: {
              title: t('Vencimentos'),
              crumb: <CrumbLink>{t('Vencimentos')}</CrumbLink>,
              permission: 'dues'
            }
          },
          {
            path: '/exportar-documentos',
            element: <Documents/>,
            handle: {
              title: t('Exportar Documentos'),
              crumb: <CrumbLink>{t('Exportar Documentos')}</CrumbLink>,
              permission: 'export_documents'
            }
          },
          {
            path: '/usuarios',
            handle: {
              crumb: <CrumbLink to='/usuarios'>{t('Usuários')}</CrumbLink>,
              permission: 'user'
            },
            children: [
              {
                index: true,
                element: <UserList/>,
                handle: {
                  title: t('Usuários')
                },
              },
              {
                path: '/usuarios/novo',
                element: <UserPage/>,
                handle: {
                  title: t('Cadastro de usuário'),
                  crumb: <CrumbLink>{t('Cadastro de usuário')}</CrumbLink>
                }
              },
              {
                path: '/usuarios/:guid',
                element: <UserPage/>,
                handle: {
                  title: t('Editar usuário'),
                  crumb: <CrumbLink>{t('Editar usuário')}</CrumbLink>
                }
              },
            ]
          },
          {
            path: '/perfis',
            handle: {
              crumb: <CrumbLink to='/perfis'>{t('Perfis de Acesso')}</CrumbLink>,
              permission: 'user_profiles'
            },
            children: [
              {
                index: true,
                element: <UserProfiles/>,
                handle: {
                  title: t('Perfis de Acesso')
                }
              },
              {
                path: '/perfis/novo',
                element: <UserProfile/>,
                handle: {
                  title: t('Cadastrar perfil'),
                  crumb: <CrumbLink>{t('Cadastrar perfil')}</CrumbLink>
                }
              },
              {
                path: '/perfis/:guid',
                element: <UserProfile/>,
                handle: {
                  title: t('Editar perfil'),
                  crumb: <CrumbLink>{t('Editar perfil')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/empresa',
            element: <Company/>,
            handle: {
              title: t('Editar empresa'),
              crumb: <CrumbLink>{t('Editar empresa')}</CrumbLink>,
              permission: 'companies'
            }
            // path: '/empresas',
            // handle: {
            //   crumb: <CrumbLink to='/empresas'>{t('Empresas')}</CrumbLink>,
            //   permission: 'companies'
            // },
            // children: [
            //   {
            //     index: true,
            //     element: <CompanyList/>,
            //     handle: {
            //       title: t('Empresas')
            //     }
            //   },
            //   {
            //     path: '/empresas/novo',
            //     element: <Company/>,
            //     handle: {
            //       title: t('Cadastro de empresa'),
            //       crumb: <CrumbLink>{t('Cadastro de empresa')}</CrumbLink>
            //     }
            //   },
            //   {
            //     path: '/empresas/:guid',
            //     element: <Company/>,
            //     handle: {
            //       title: t('Editar empresa'),
            //       crumb: <CrumbLink>{t('Editar empresa')}</CrumbLink>
            //     }
            //   }
            // ]
          },
          {
            path: '/filial',
            element: <Establishment/>,
            handle: {
              title: t('Editar filial'),
              crumb: <CrumbLink to='/filial'>{t('Filial')}</CrumbLink>,
              permission: 'establishments'
            },
            // path: '/filiais',
            // handle: {
            //   crumb: <CrumbLink to='/filiais'>{t('Filiais')}</CrumbLink>,
            //   permission: 'establishments'
            // },
            // children: [
            //   {
            //     path: '/filiais',
            //     element: <EstablishmentList/>,
            //     handle: {
            //       title: t('Filiais')
            //     }
            //   },
            //   {
            //     path: '/filiais/novo',
            //     element: <Establishment/>,
            //     handle: {
            //       title: t('Cadastro de filial'),
            //       crumb: <CrumbLink>{t('Cadastro de filial')}</CrumbLink>
            //     }
            //   },
            //   {
            //     path: '/filiais/:guid',
            //     element: <Establishment/>,
            //     handle: {
            //       title: t('Editar filial'),
            //       crumb: <CrumbLink>{t('Editar filial')}</CrumbLink>
            //     }
            //   }
            // ]
          },
          {
            path: '/operacoes',
            handle: {
              crumb: <CrumbLink to='/operacoes'>{t('Operações')}</CrumbLink>,
              permission: 'operations'
            },
            children: [
              {
                path: '/operacoes',
                element: <OperationsList/>,
                handle: {
                  title: t('Operações')
                }
              },
              {
                path: '/operacoes/novo',
                element: <Operation/>,
                handle: {
                  title: t('Cadastro de operação'),
                  crumb: <CrumbLink>{t('Cadastro de operação')}</CrumbLink>
                }
              },
              {
                path: '/operacoes/:guid',
                element: <Operation/>,
                handle: {
                  title: t('Editar operação'),
                  crumb: <CrumbLink>{t('Editar operação')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/tarefas',
            handle: {
              crumb: <CrumbLink to='/tarefas'>{t('Tarefas')}</CrumbLink>,
              permission: 'tasks'
            },
            children: [
              {
                path: '/tarefas',
                element: <TaskList/>,
                handle: {
                  title: t('Tarefas')
                }
              },
              {
                path: '/tarefas/novo',
                element: <Task/>,
                handle: {
                  title: t('Cadastro de tarefa'),
                  crumb: <CrumbLink>{t('Cadastro de tarefa')}</CrumbLink>
                }
              },
              {
                path: '/tarefas/:guid',
                element: <Task/>,
                handle: {
                  title: t('Editar tarefa'),
                  crumb: <CrumbLink>{t('Editar tarefa')}</CrumbLink>
                }
              }
            ]
          },
          {
            path: '/suporte',
            element: <Support/>,
            handle: {
              title: t('Suporte ao Cliente'),
              crumb: <CrumbLink to='/suporte'>{t('Suporte ao Cliente')}</CrumbLink>,
            },
          },
          {
            path: '/ajuda',
            element: <Help/>,
            handle: {
              title: t('Ajuda'),
              crumb: <CrumbLink to='/ajuda'>{t('Ajuda')}</CrumbLink>,
            },
          },
          {
            path: '*',
            element: <Error404/>
          }
        ]
      }
    ]
  },
]);

function Router(){
  const { t } = useTranslation('Pages');

  return(
    <RouterProvider router={router(t)}/>
  )
} export default Router;